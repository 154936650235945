<template>
  <div class="col-12">
    <v-card>
      <v-card-title class="">
        <span class="blue--text text-h6"> Yeni İş Emri </span>
        <v-spacer />
        <v-btn class="mr-2" outlined color="indigo" dense @click="newWorkOrderDialog = true">
          <v-icon left>
            mdi-plus
          </v-icon>
          Yeni İş Emri
        </v-btn>

        <v-btn class="mr-2" outlined color="indigo" dense @click="newWorkOrderDialog = true">
          <v-icon left>
            mdi-settings
          </v-icon>
          Ayarlar
        </v-btn>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn fab dark small color="cyan" v-on="on" @click="exportDocument">
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </template>
          <span>Export to Excel</span>
        </v-tooltip>
      </v-card-title>
      <v-divider />
      <!-- 
      <div class="col-12 text-right">
        <v-row>
          <v-col
            cols="6"
            sm="2"
            md="2"
            class="pr-0"
          >
            <v-select
              v-model="sahaModel"
              :items="santralList"
              label="Sahaya Göre Filtrele"
              outlined
              dense
              multiple
              hide-details
            >
              <template v-slot:selection="{item, index}">
                <span
                  v-if="index === 0"
                  style="max-width: 120px"
                  class="d-inline-block text-truncate"
                >{{ item.text }}</span>
                <span
                  v-if="index === 1"
                  class="grey--text text-caption"
                >(+{{ sahaModel.length - 1 }})</span>
              </template>
            </v-select>
          </v-col>

          <v-col
            cols="6"
            sm="2"
            md="2"
            class="pr-0 "
          >
            <v-select
              v-model="userModel"
              :items="userList"
              label="Atanan Kişiye Göre Filtrele"
              outlined
              dense
              multiple
              hide-details
            >
              <template v-slot:selection="{item, index}">
                <span
                  v-if="index === 0"
                  style="max-width: 120px"
                  class="d-inline-block text-truncate"
                >{{ item.text }}</span>
                <span
                  v-if="index === 1"
                  class="grey--text text-caption"
                >(+{{ userModel.length - 1 }})</span>
              </template>
            </v-select>
          </v-col>

          <v-col
            cols="6"
            sm="2"
            md="2"
            class=" pr-0"
          >
            <v-select
              v-model="categoryModel"
              :items="categoryList"
              :menu-props="{maxHeight: '400'}"
              label="Kategoriye Göre Filtrele"
              multiple
              outlined
              dense
              hide-details
            >
              <template v-slot:selection="{item, index}">
                <span
                  v-if="index === 0"
                  style="max-width: 120px"
                  class="d-inline-block text-truncate"
                >{{ item.text }}</span>
                <span
                  v-if="index === 1"
                  class="grey--text text-caption"
                >
                  (+{{ categoryModel.length - 1 }})
                </span>
              </template>
            </v-select>
          </v-col>

          <v-col
            cols="6"
            sm="1"
            md="1"
            class=" pr-0"
          >
            <v-select
              v-model="severityModel"
              :items="severity"
              label="Önceliğe Göre Filtrele"
              outlined
              dense
              multiple
              hide-details
            >
              <template v-slot:selection="{item, index}">
                <span
                  v-if="index === 0"
                  style="max-width: 120px"
                  class="d-inline-block text-truncate"
                >{{ item.text }}</span>
                <span
                  v-if="index === 1"
                  class="grey--text text-caption"
                >(+{{ severityModel.length - 1 }})</span>
              </template>
            </v-select>
          </v-col>

          <v-col
            cols="6"
            sm="1"
            md="1"
            class="pr-0 "
          >
            <v-select
              v-model="statusModel"
              :items="status"
              label="Duruma Göre Filtrele"
              outlined
              dense
              multiple
              hide-details
            >
              <template v-slot:selection="{item, index}">
                <span
                  v-if="index === 0"
                  class="grey--text text-caption"
                >(+{{ statusModel.length - 1 }})</span>
              </template>
            </v-select>
          </v-col>

          <v-col
            cols="6"
            sm="1"
            md="1"
            class=" pr-0"
          >
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{on, attrs}">
                <v-text-field
                  v-model="date"
                  label=""
                  readonly
                  v-bind="attrs"
                  dense
                  outlined
                  hide-details
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="date"
                no-title
                scrollable
              >
                <v-spacer />
                <v-btn
                  text
                  color="primary"
                  @click="menu = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col
            cols="6"
            sm="2"
            md="2"
            class="pr-0"
          >
            <v-text-field
              outlined
              class="ml-2 mr-2"
              dense
              label="Ara"
              hide-details
              @click:append-outer="searchFarm"
            />
          </v-col>
          <v-col
            cols="6"
            sm="1"
            md="1"
            class=" pr-0"
          >
            <v-btn
              class="mr-2"
              outlined
              color="indigo"
              dense
            >
              Filtrele
            </v-btn>
          </v-col>
        </v-row>
      </div>
 -->
      <v-col class="col-12 text-right">
        <v-data-table :headers="test" :items="desserts" :items-per-page="5" class="elevation-1" />
      </v-col>

      <v-dialog v-model="newWorkOrderDialog" persistent max-width="800px" height="700px">
        <v-card elevation="0">
          <v-card-title class="">
            <span class="blue--text text-h6">Yeni İş Emri </span>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-container>
              <v-stepper v-model="e1">
                <v-stepper-header>
                  <v-stepper-step :complete="e1 > 1" step="1">
                    Temel Bilgiler
                  </v-stepper-step>

                  <v-divider />

                  <v-stepper-step :complete="e1 > 2" step="2">
                    İlişkili Nesneler
                  </v-stepper-step>

                  <v-divider />

                  <v-stepper-step step="3">
                    Ekle
                  </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                  <v-stepper-content step="1">
                    <v-col class="d-flex mb-4 formelement">
                      <v-select
                        v-model="formData.santral"
                        class="col-3 mr-2"
                        :items="santralList"
                        label="Santral Listesi"
                        dense
                        outlined
                        hide-details
                      />

                      <v-select
                        v-model="formData.username"
                        label="Kullanıcı*"
                        multiple
                        class="col-3 ml-2"
                        :items="userList"
                        dense
                        outlined
                        hide-details
                      />
                    </v-col>

                    <v-col class="d-flex mb-4 formelement">
                      <v-select
                        v-model="formData.severity"
                        label="Öncelik*"
                        class="col-3 mr-2"
                        :items="items"
                        dense
                        hide-details
                        outlined
                      />

                      <v-select
                        v-model="formData.category"
                        label="Kategory*"
                        :items="fruits"
                        class="col-3 ml-2"
                        dense
                        outlined
                        hide-details
                      />
                    </v-col>

                    <v-col cols="12 mb-1  formelement">
                      <v-textarea
                        v-model="formData.description"
                        outlined
                        name="input-7-4"
                        auto-grow
                        label="Açıklama*"
                        dense
                        hide-details
                      />
                    </v-col>

                    <v-col class=" col-12 d-flex pt-0 mt-0 mb-0 pb-0 pl-0">
                      <div class="col-6  pt-0 mt-0 mb-0 pb-0 ml-0">
                        <span class="text-h8 pt-1">Problemin Başlangıç Tarihi</span>

                        <div class="col-12 d-flex ml-0 pl-0 pt-0 mt-0">
                          <div class="col-7 ml-0 pl-0 pt-0 mt-0">
                            <v-menu
                              ref="menu"
                              v-model="menu"
                              :close-on-content-click="false"
                              :return-value.sync="date"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="date"
                                  label=""
                                  readonly
                                  v-bind="attrs"
                                  dense
                                  outlined
                                  hide-details
                                  v-on="on"
                                />
                              </template>
                              <v-date-picker v-model="date" no-title scrollable>
                                <v-spacer />
                                <v-btn text color="primary" @click="menu = false">
                                  Cancel
                                </v-btn>
                                <v-btn text color="primary" @click="$refs.menu.save(date)">
                                  OK
                                </v-btn>
                              </v-date-picker>
                            </v-menu>
                          </div>
                          <div class="col-5 ml-0 pl-0 pt-0 mt-0">
                            <v-text-field
                              v-model="modal2"
                              label="Saat"
                              value="12:30:00"
                              type="time"
                              dense
                              outlined
                              hide-details
                            />
                          </div>
                        </div>
                      </div>

                      <div class="col-6  pt-0 mt-0 mb-0 pb-0 ">
                        <span class="text-h8"
                          >Bu İş Emrini Geçmiş Tarihi olarak oluşturmak istiyorum</span
                        >
                        <v-radio-group class="  mt-0 pt-0   mt-0 mb-0 pb-0">
                          <div class="d-flex mt-0 pt-0   mt-0">
                            <div class="col-4 mt-0 pt-0   mt-0">
                              <v-radio label="Evet" value="radio-1" />
                            </div>

                            <div class="col-4 mt-0 pt-0">
                              <v-radio label="Hayır" value="radio-2" />
                            </div>
                          </div>
                        </v-radio-group>
                      </div>
                    </v-col>

                    <v-btn color="primary" dense outlined @click="e1 = 2">
                      <v-icon>mdi-menu-right-outline</v-icon>
                      Sonraki
                    </v-btn>
                  </v-stepper-content>

                  <v-stepper-content step="2">
                    <v-col class="col-12 d-flex mb-0 pb-0  pt-0 mt-0 ml-0 pl-0">
                      <div class="col-6 mb-0 pb-0  pt-0 mt-0 ml-0 pl-0">
                        <v-select
                          v-if="formData.santral"
                          v-model="formData.datalogger"
                          :items="dataloggerList[formData.santral]"
                          label="Datalogger"
                          multiple
                          dense
                          outlined
                          hide-details
                        >
                          <template v-slot:selection="{ item, index }">
                            <span
                              v-if="index === 0"
                              style="max-width: 120px"
                              class="d-inline-block text-truncate"
                              >{{ item.text }}</span
                            >
                            <span v-if="index === 1" class="grey--text text-caption"
                              >(+{{ dataloggerModel.length - 1 }})</span
                            >
                          </template>
                        </v-select>
                      </div>

                      <div class="col-6 mb-0 pb-0  pt-0 mt-0 ml-0 pl-0">
                        <v-select
                          v-if="formData.santral"
                          v-model="formData.inverter"
                          :items="inverterList[formData.santral]"
                          label="inverter*"
                          multiple
                          dense
                          outlined
                          hide-details
                        >
                          <template v-slot:selection="{ item, index }">
                            <span
                              v-if="index === 0"
                              style="max-width: 120px"
                              class="d-inline-block text-truncate"
                              >{{ item.text }}</span
                            >
                            <span v-if="index === 1" class="grey--text text-caption"
                              >(+{{ inverterModel.length - 1 }})</span
                            >
                          </template>
                        </v-select>
                      </div>
                    </v-col>

                    <div class="col-12 d-flex  pt-0 mt-0 ml-0 pl-0">
                      <div class="col-6 mb-0 pb-0 ml-0 pl-0">
                        <v-select
                          v-if="formData.santral"
                          v-model="formData.stringbox"
                          :items="stringboxList[formData.santral]"
                          label="stringbox*"
                          multiple
                          dense
                          outlined
                          hide-details
                        >
                          <template v-slot:selection="{ item, index }">
                            <span
                              v-if="index === 0"
                              style="max-width: 120px"
                              class="d-inline-block text-truncate"
                              >{{ item.text }}</span
                            >
                            <span v-if="index === 1" class="grey--text text-caption"
                              >(+{{ stringboxList.length - 1 }})</span
                            >
                          </template>
                        </v-select>
                      </div>
                      <div class="col-6 ">
                        <v-select
                          v-if="formData.santral"
                          v-model="formData.sensor"
                          :items="sensorList[formData.santral]"
                          label="sensor*"
                          multiple
                          dense
                          outlined
                          hide-details
                        >
                          <template v-slot:selection="{ item, index }">
                            <span
                              v-if="index === 0"
                              style="max-width: 120px"
                              class="d-inline-block text-truncate"
                              >{{ item.text }}</span
                            >
                            <span v-if="index === 1" class="grey--text text-caption"
                              >(+{{ sensorList.length - 1 }})</span
                            >
                          </template>
                        </v-select>
                      </div>
                    </div>
                    <v-btn color="primary" dense outlined @click="e1 = 3">
                      <v-icon>mdi-menu-right-outline</v-icon>
                      Sonraki
                    </v-btn>
                  </v-stepper-content>

                  <v-stepper-content step="3">
                    <v-data-table
                      dense
                      :headers="headers"
                      :items="desserts"
                      item-key="name"
                      class="elevation-1"
                    />

                    <v-file-input
                      show-size
                      counter
                      multiple
                      label="File input"
                      dense
                      outlined
                      filled
                    />

                    <v-btn color="primary" dense outlined @click="e1 = 1">
                      <v-icon>mdi-menu-right-outline</v-icon>
                      Sonraki
                    </v-btn>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
          
            <v-btn text color="primary" dense outlined filled @click="saveForm">
              Kaydet
            </v-btn>
            <v-btn text color="primary" dense outlined filled @click="newWorkOrderDialog = false">
              Kapat
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <ExportDocument :export-document-open="exportDocumentOpen" />
    </v-card>
  </div>
</template>

<script>
import { getUserList } from "@/api/Request/user";
import helper from "@/api/helper";
import { getDataloggerList } from "@/api/Request/datalogger";

export default {
  data: () => ({
    e1: 1,
    e3: null,
    e4: null,
    time: null,
    menu2: false,
    modal2: false,
    dialog: false,
    states: ["Müdür Hesabı", "Teknisyen Hesabı"],
    fruits: ["Düşük", "Orta", "Yüksek", "Kritik"],
    test: ["Jüpider", "Saturn"],
    items: [
      "Bilinmeyen",
      "Müşteri",
      "Veri Kaybı",
      "Baglantı hataları",
      "İnveter hataları",
      "String hatası",
      "Sıcaklık hatası"
    ],
    sahaModel: "",
    sahaList: "",
    userModel: "",

    categoryModel: "",

    filterPriorityModel: "",
    filterPriority: "",
    filterStatusModel: "",
    filterStatus: "",
    selectedFruits: [],
    exportDocumentOpen: {
      open: false,
      tableID: null
    },
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    menu: false,
    modal: false,
    user: false,
    filterdialog: false,
    newWorkOrderDialog: false,
    userList: [],
    test1: [
      {
        text: "ID",
        align: "start",
        sortable: false,
        value: "name"
      },
      { text: "Saha", value: "calories" },
      { text: "Açıklama", value: "calories" },
      { text: "Kategori", value: "calories" },
      { text: "Durum", value: "calories" },
      { text: "Öncelik", value: "calories" },
      { text: "Atanan Kişi", value: "calories" },
      { text: "Açılış Tarihi", value: "calories" }
    ],
    desserts: [],
    headers: [
      {
        text: "Dosya Adı",
        align: "start",
        sortable: false,
        value: "name"
      },
      { text: "Yükleyen Kişi/Zaman", value: "" },
      { text: "İşlemler", value: "" }
    ],
    dataloggerList: [],
    dataloggerModel: [],
    santralModel: [],
    inverterList: [],
    inverterModel: [],
    stringboxList: [],
    stringboxModel: [],
    sensorList: [],
    sensorModel: [],
    formData: {
      santral: "",
      username: "",
      severity: "",
      category: "",
      description: "",
      datalogger: "",
      inverter: "",
      stringbox: "",
      sensor: ""
    }
  }),

  computed: {
    categoryList() {
      return [
        { text: "Bilinmeyen", value: "unknown" },
        { text: "Yetkililer", value: "authorities" },
        { text: "Düzeltici bakım", value: "corrective_maintenance" },
        { text: "Müşteri", value: "customer" },
        { text: "Veri kaybı", value: "dataloss" },
        { text: "Hata", value: "error" },
        { text: "Olaganüstü hal", value: "stateofemergency" },
        { text: "Şebeke kesintisi", value: "mainoutage" },
        { text: "Bilgilendirme", value: "informatin" },
        { text: "Sigorta talebi", value: "insurance_claim" },
        { text: "Arıza tespit edilemedi", value: "fault_not_detected" },
        { text: "Güç limitlemesi", value: "powerlimition" },
        { text: "Önleyici bakım", value: "preventive_maintrnance" },
        { text: "Onarım", value: "repair" },
        { text: "Tedarik şebekesi", value: "supply_network" },
        { text: "Garanti talebi", value: "warranty_claim" }
      ];
    },

    severity() {
      return [
        { text: "Düşük", value: "1" },
        { text: "Orta", value: "2" },
        { text: "Yüksek", value: "3" },
        { text: "Kritik", value: "4" }
      ];
    },
    status() {
      return [
        { text: "açık", value: "open" },
        { text: "reddedildi", value: "denied" },
        { text: "devam ediyor", value: "continues" },
        { text: "tamamlandı", value: "completed" },
        { text: "kapat", value: "close" },
        { text: "degerlendirme reddedildi", value: "assessment_denied" }
      ];
    },

    likesAllFruit() {
      return this.selectedFruits.length === this.fruits.length;
    },
    likesSomeFruit() {
      return this.selectedFruits.length > 0 && !this.likesAllFruit;
    },
    // eslint-disable-next-line vue/return-in-computed-property
    santralList() {
      const { santralRawData } = this.$store.getters;
      const santral = [];
      if (Object.keys(santralRawData).length > 0) {
        console.log(santralRawData);
        Object.keys(santralRawData).forEach(i => {
          santral.push({
            text: santralRawData[i].santral,
            value: santralRawData[i].santralCode
          });
        });
        return santral;
      }
    },
    // eslint-disable-next-line vue/return-in-computed-property

    icon() {
      if (this.likesAllFruit) return "mdi-close-box";
      if (this.likesSomeFruit) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    }
  },
  mounted() {
    this.selectedFarmFunc();

    setTimeout(() => {
      this.getDatalogger();
      this.getInverterList();
      this.getStringList();
      this.getSensorList();
    }, 3000);
  },

  methods: {
    searchFarm() {},
    saveForm() {
      const { formData } = this;
      const {
        santral,
        username,
        severity,
        category,
        description,
        datalogger,
        inverter,
        stringbox,
        sensor,
      } = formData;
      const data = {
        santral,
        username,
        severity,
        category,
        description,
        datalogger,
        inverter,
        stringbox,
        sensor
      };
      console.log(data);
      // this.$store.dispatch('createWorkOrder', data);
    },
    selectedFarmFunc() {
      const storage = window.localStorage;
      const userCompany = JSON.parse(window.atob(storage.getItem("user_company")));
      this.companyCode = userCompany.companyCode;
      this.prefix = userCompany.prefix;
      console.log(this.prefix);
      console.log(this.companyCode);
      this.getUser();
    },
    exportDocument() {
      this.exportDocumentOpen.open = true;
      this.exportDocumentOpen.tableID = this.santralDataTable;
    },
    toggle() {
      this.$nextTick(() => {
        if (this.likesAllFruit) {
          this.selectedFruits = [];
        } else {
          this.selectedFruits = this.fruits.slice();
        }
      });
    },
    getUser() {
      const params = {
        condiniton: {
          prefix: this.prefix
        }
      };
      const users = [];
      // eslint-disable-next-line vue/no-async-in-computed-properties
      getUserList(params).then(res => {
        if (res.data.success === "true") {
          const rest = res.data.data;

          if (rest.length > 0) {
            Object.keys(rest).forEach(i => {
              let f = "";
              let l = "";
              if ("firstname" in rest[i]) {
                f = rest[i].firstname;
              }

              if ("lastname" in rest[i]) {
                l = rest[i].lastname;
              }

              users.push({
                text: `${f} ${l}`,
                value: rest[i].username
              });
            });
          }
        }
        this.userList = users;
      });
    },
    getDatalogger() {
      const params = {
        condiniton: {
          prefix: this.prefix
        }
      };

      const device = [];

      getDataloggerList(params).then(res => {
        console.log(res);
        if (res.data.success === "true") {
          if (res.data.data.length > 0) {
            Object.keys(res.data.data).forEach(i => {
              const { santralCode } = res.data.data[i];

              if (!(santralCode in device)) {
                device[santralCode] = [];
              }
              device[santralCode].push({
                text: res.data.data[i].deviceName,
                value: res.data.data[i].deviceName
              });
            });
          }
        }
        this.dataloggerList = device;
      });
    },
    getInverterList() {
      // this.inverterModel = device;
      const invList = {};
      const { inverterData } = this.$store.getters;
      console.log(inverterData);
      console.log(this.santralModel);
      if (Object.keys(inverterData).length > 0) {
        Object.keys(inverterData).forEach(s => {
          const { santralCode } = inverterData[s];

          if (!(santralCode in invList)) {
            invList[santralCode] = [];
          }

          invList[santralCode].push({
            value: inverterData[s].INVERTER,
            text: inverterData[s].INVERTERNAME
          });
        });
      }

      console.log(invList);
      this.inverterList = invList;
    },
    getStringList() {
      // this.inverterModel = device;
      const invList = {};
      const { stringboxData } = this.$store.getters;
      console.log(stringboxData);
      console.log(this.stringboxData);
      if (Object.keys(stringboxData).length > 0) {
        Object.keys(stringboxData).forEach(s => {
          const { santralCode } = stringboxData[s];

          if (!(santralCode in invList)) {
            invList[santralCode] = [];
          }

          invList[santralCode].push({
            value: stringboxData[s].STRINGBOX,
            text: stringboxData[s].STRINGBOXNAME
          });
        });
      }

      console.log(invList);
      this.stringboxList = invList;
    },
    getSensorList() {
      // this.inverterModel = device;
      const invList = {};
      const { sensorData } = this.$store.getters;
      console.log(sensorData);
      console.log(this.stringboxData);
      if (Object.keys(sensorData).length > 0) {
        Object.keys(sensorData).forEach(s => {
          const { santralCode } = sensorData[s];

          if (!(santralCode in invList)) {
            invList[santralCode] = [];
          }

          invList[santralCode].push({
            value: sensorData[s].SENSOR,
            text: sensorData[s].SENSORNAME
          });
        });
      }

      console.log(invList);
      this.sensorList = invList;
    }
  }
};
</script>

<style scoped>
.formelement {
  margin-top: 2px;
  margin-bottom: 4px;
  padding-bottom: 2px;
  padding-top: 1px;
}
</style>
